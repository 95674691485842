<template>
  <div id="screen"></div>
</template>

<script>
export default {
  name: 'LoginAsUser',
  components: {},
  setup() {
    return {}
  }
}
</script>
<script setup>
import {ElLoading} from "element-plus";
import {useRoute} from "vue-router";
import axios from "axios";
import Cookies from "js-cookie";

const route = useRoute()
let loading = ElLoading.service({target: '#screen'})

const loginAsUser = () => {
  axios.get(`user/login-as-user/${route.params.token}`).then((res) => {
    Cookies.set('token', res.data.token.plainTextToken)
  }).then(() => {
    window.location.replace("/");
  })
}

loginAsUser();

</script>
<style scoped>
</style>
